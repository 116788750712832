.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
  background-color: black;
}

.App-logo {
  max-width: 25%;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-header {
    animation: App-logo-enter 2s ease-in-out;
  }
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column-reverse;
  align-content: flex-end;
  vertical-align: bottom;
  text-align: right;
  background-image: url("images/MainPage.jpg");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  align-items: flex-end;
}

@keyframes App-logo-enter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.MainContainer {
  z-index: 10;
  padding: 10px 3vw;
  margin: 5vw;
  border-radius: 5px;
  border: thin #aaa solid;
  position: relative;
  color: #fff;
  font-family: "Open Sans";
  background: #000;
}
.MainContainer::before {
  z-index: 0;

  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  background-image: url("images/bg-Sections.jpg");
  background-size: cover;
  opacity: 0.6;
}

.MainSection {
  z-index: 10;
  position: relative;
  margin: 0px auto;
  padding-top: 20px;
  border-bottom: double #eee thin;
  width: 80%;
  max-width: 1000px;
}
.MainSection:last-child {
  border-bottom: none;
}
.Title {
  font-size: 25px;
  letter-spacing: 1px;
  font-variant: small-caps;
  font-weight: 600;
}

.Subtitle {
  font-size: 20px;
  letter-spacing: 1px;
  font-variant: small-caps;
  font-weight: 600;
}

.Text {
  font-size: 16px;
  letter-spacing: 0.7px;
  line-height: 28px;
}
.InfoText {
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 18px;
  text-align: justify;
}
.InfoArea {
  padding: 10px;
  text-align: center;
}
.Caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  padding: 5px;
  text-align: justify;
}

.ProfilePicture {
  max-width: 100%;
  object-fit: contain;
  object-position: center;
}
